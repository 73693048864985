<template>

  <h1>
    <span>Операция: {{title}} ({{cnt}})</span>
    <div v-if="ext_fields.datecontrol" class="shift-forward">
      <span>Посмотреть на </span>
      <input @change="recalcAndSaveDate"
             type="number" min="0" max="100" v-model="shift_forward_day" />
      <span> {{shift_forward_day % 10 == 1 ? ' день' :
        shift_forward_day % 10 == 2 || shift_forward_day % 10 == 3
        || shift_forward_day % 10 == 4 ? ' дня' : ' дней'}} в будущее</span>
    </div>
  </h1>

  <div class="row-flex" >
    <div class="row-flex--item head" style="flex:2">Приоритет</div>
    <div class="row-flex--item head" style="flex:3">Заказ</div>
    <div v-if="ext_fields.paper" class="row-flex--item head" style="flex:2">Наличие бумаги</div>
    <div v-if="ext_fields.operation" class="row-flex--item head" style="flex:2">Операция</div>
    <div v-else-if="calc_element_show" class="row-flex--item head" style="flex:6">Элемент</div>
    <div v-if="ext_fields.adjustment" class="row-flex--item head"
         style="flex:2">Зак. на прилад.</div>
    <div v-if="ext_fields.pontoon" class="row-flex--item head"
         style="flex:6">Пантон</div>
  </div>
  <div v-for="(order, index) in orders" :key="index">
    <div class="row-flex">
      <div class="row-flex--item" style="flex:2" :class="{
        'green' : order.statusBuff >= 66 && order.statusBuff <= 100,
        'yellow': order.statusBuff >= 33 && order.statusBuff < 66,
        'red': order.statusBuff >= 0 && order.statusBuff < 33,
        'black': order.statusBuff < 0,
        }">{{order.priority}}</div>
      <div class="row-flex--item" style="flex:3" :class="{
        'green' : order.statusBuff >= 66 && order.statusBuff <= 100,
        'yellow': order.statusBuff >= 33 && order.statusBuff < 66,
        'red': order.statusBuff >= 0 && order.statusBuff < 33,
        'black': order.statusBuff < 0,
        }">{{order.number}}</div>
      <div v-if="ext_fields.paper" class="row-flex--item" style="flex:2" :class="{
        'green' : order.statusBuff >= 66 && order.statusBuff <= 100,
        'yellow': order.statusBuff >= 33 && order.statusBuff < 66,
        'red': order.statusBuff >= 0 && order.statusBuff < 33,
        'black': order.statusBuff < 0,
        }">{{order.withPaper}}</div>
      <div v-if="ext_fields.operation" class="row-flex--item pointer" style="flex:2" :class="{
        'green' : order.statusBuff >= 66 && order.statusBuff <= 100,
        'yellow': order.statusBuff >= 33 && order.statusBuff < 66,
        'red': order.statusBuff >= 0 && order.statusBuff < 33,
        'black': order.statusBuff < 0,
        }"><div @click="toggleOperations(index)" class="table-body-td pointer" style="flex:2;"
                v-if="order.show === false">
            ({{order.elements.length}})
            <i class="fa fa-plus" aria-hidden="true"></i>
          </div>
          <div @click="toggleOperations(index)" class="table-body-td pointer" style="flex:2" v-else>
            ({{order.elements.length}})
            <i class="fa fa-minus" aria-hidden="true"></i>
          </div>
          <!--{{order.operation}} {{order.element ? ' ('+order.element+')' : ''}}--></div>
      <div v-else-if="order.element && ext_fields.pontoon == false"
           class="row-flex--item" style="flex:6" :class="{
        'green' : order.statusBuff >= 66 && order.statusBuff <= 100,
        'yellow': order.statusBuff >= 33 && order.statusBuff < 66,
        'red': order.statusBuff >= 0 && order.statusBuff < 33,
        'black': order.statusBuff < 0,
        }">{{order.element}}</div>
      <div v-if="ext_fields.adjustment" class="row-flex--item" style="flex:2" :class="{
        'green' : order.statusBuff >= 66 && order.statusBuff <= 100,
        'yellow': order.statusBuff >= 33 && order.statusBuff < 66,
        'red': order.statusBuff >= 0 && order.statusBuff < 33,
        'black': order.statusBuff < 0,
        }">{{order.flag_cust_for_adj == 1 ? 'Да' : 'Нет' }}</div>
      <div v-if="ext_fields.pontoon" class="row-flex--item" style="flex:6" :class="{
        'green' : order.statusBuff >= 66 && order.statusBuff <= 100,
        'yellow': order.statusBuff >= 33 && order.statusBuff < 66,
        'red': order.statusBuff >= 0 && order.statusBuff < 33,
        'black': order.statusBuff < 0,
        }">{{order.flug_cust_for_pontoon }}</div>
    </div>
    <tamplate v-if="order.show">
      <div class="row-flex" v-for="(item, index) in order.elements" :key="index">
        <div class="row-flex--item bord" style="flex:2"></div>
        <div class="row-flex--item bord" style="flex:3">{{order.number}}</div>
        <div class="row-flex--item bord" style="flex:6">{{item.oper}}</div>
      </div>
    </tamplate>
  </div>

</template>

<script>
import axios from 'axios';

export default {
  name: 'OperationDetail',
  data() {
    return {
      error: [],
      title: '',
      orders: [],
      cnt: 0,
      ext_fields: {
        paper: false,
        operation: false,
        adjustment: false,
        pontoon: false,
      },

      shift_forward_day: 0,
    };
  },
  created() {
    this.getPageInfo();
  },
  computed: {
    calc_element_show() {
      if (this.ext_fields.pontoon === false && this.orders.length && this.orders[0].element) {
        return 1;
      }
      return 0;
    },
  },
  methods: {
    getPageInfo() {
      const str = '/api/page-operation-test2?name_operation=';
      axios.get(str + this.$route.params.code)
        .then((res) => {
          if (res.data.success === 1) {
            this.title = res.data.page_info.title;
            this.orders = res.data.page_info.orders;
            this.cnt = res.data.cnt;
            this.ext_fields.paper = res.data.paper;
            this.ext_fields.operation = res.data.operation;
            this.ext_fields.adjustment = res.data.adjustment;
            this.ext_fields.pontoon = res.data.pontoon;
            this.ext_fields.datecontrol = res.data.datecontrol;

            this.shift_forward_day = res.data.page_info.shift_forward_day;
          }
        })
        .catch((e) => {
          this.error.push(e);
        });
    },
    recalcAndSaveDate() {
      axios.post('/api/chg-shift-date-trick-paper', { shift_days: this.shift_forward_day })
        .then((res) => {
          if (res.data.status === 1) {
            this.getPageInfo();
          } else {
            alert('ошибка очистки дат');
          }
          this.loader = false;
        })
        .catch((e) => {
          this.error.push(e);
        });
    },
    toggleOperations(index) {
      if (this.orders[index].show === true) {
        this.orders[index].show = false;
      } else {
        this.orders[index].show = true;
      }
    },
  },
};
</script>

<style scoped>
.row-flex{display: flex; padding:2px 10px; margin-left:-10px; margin-right:-10px;}
.row-flex--item{flex: 1; padding:2px 10px; font-size:40px; line-height:50px;}
.row-flex--item.head{background:#3e5977; color:#eee; font-size:32px; line-height:44px;
  padding-bottom:8px;}
.row-flex--item.right{text-align:right}

.row-flex--item.black{background: #14171a; color: #eee;}
.row-flex--item.red{background: #e33838; color: #eee;}
.row-flex--item.yellow{background: #f2f251; color: #000;}
.row-flex--item.green{background: #2e842e; color: #eee;}

.bord{ border-bottom: 1px solid #000; font-size:30px; line-height:30px;}

.pointer{cursor:pointer}

.shift-forward{
  font-size: 16px;
  line-height: 18px;
  display:inline-block;
  margin-left:12px;
  vertical-align: middle;
}
.shift-forward input{height: 30px;
  width: 70px;}
</style>
